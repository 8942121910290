<template>
  <div class="text-center">
    <ion-grid>
      <ion-row>
        <ion-col align-self-center size="12" size-sm>
          <ion-icon class="hero-success-icon bounceIn" name="ios-checkmark-circle-outline"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col align-self-center size="12" size-sm>
          <h2>{{ $route.params.title || "Success" }}</h2>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col size-md="6" push-md="3">
          <h6 class="pad-lr-twenty">
            {{ $route.params.body || "Your request has been successfully completed." }}
          </h6>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col align-self-center size="12" size-sm>
          <p class="margin-top-twenty" v-if="$route.params.navlink" v-dompurify-html="$route.params.navlink"></p>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</template>

<script>
import { addIcons } from "ionicons";
import { checkmarkCircleOutline } from "ionicons/icons";
import AuthState from "@/utils/Auth";

addIcons({
  "ios-checkmark-circle-outline": checkmarkCircleOutline.ios,
  "md-checkmark-circle-outline": checkmarkCircleOutline.md
});

export default {
  name: "success",
  data() {
    return {
      isApproval: null
    };
  },
  mounted() {
    if (AuthState.isLoggedIn()) {
      this.isApproval = true;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero-success-icon {
  margin-top: 80px;
  font-size: 100px;
  color: #6fb400;
}
</style>
